import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, Card, Checkbox, Grid, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFDatePicker, RHFTextField } from '../../../../../components/hook-form';
import UploadPhoto from '../../../../../sections/giving_basket/UploadPhoto';
import NoAmountModal from '../../NoAmountModal';
import { countries } from '../../../../../_mock';
import { fDateDb } from '../../../../../utils/formatTime';
import { DONATION_TYPE } from '../../../../../config';
import { DonationAPI } from '../../../../../services/donation.services';
import createResizedFile, { convertImagesToBase64STring } from '../../../../../utils/resizeFile';

export default function BankTransferForm({ discretionary, projects, causes, disabled, onSuccess }) {

    const [selectedImages, setSelectedImages] = useState([]);
    const [noAmountOpen, setNoAmountOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const resolver = yupResolver(Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        phone: Yup.string().required('Required'),
        donationAmount: Yup.string().required('Required'),
        dobTransfer: Yup.string().required('Required'),
        file: Yup.array().min(1, "Please select a photo").required('Required')
    }))

    const defaultValues = {
        anonymous: false,
        country: 'PH',
        file: [],
        emailUpdate: false,
    }

    const methods = useForm({ defaultValues, resolver });

    const {
        reset,
        setError,
        handleSubmit,
        setValue,
        trigger,
        watch,
        clearErrors,
        formState: { errors, isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = async (data) => {
        if (!values.donationAmount) {
            setNoAmountOpen(true);
            return;
        }

        setIsLoading(true);
        const payload = await createPayload({
            discretionary,
            formData: data,
            projects,
            images: data.file,
            causes
        })
        DonationAPI.submitDonationInHouse(DONATION_TYPE.IN_HOUSE.BANK_TRANSFER_DEPOSIT, payload)
            .then((res) => {
                reset(defaultValues);
                setIsLoading(false);
                if (onSuccess) onSuccess();
            })
            .catch((err) => {
                setIsLoading(false)
                alert(`Encountered an error. Please try again.${err}`);
            });
    }

    const setFile = (file) => {
        if (file.size > 1024 * 500) {
            setValue("file", [file]);
        } else {
            setValue("file", selectedImages);
        }
        clearErrors('file');
    }

    useEffect(() => {
        setValue('donationAmount', projects.reduce((prev, val) => prev + val.amount, 0));
    }, [projects])

    useEffect(() => {
        const file = selectedImages[0];
        if (!file) return;

        setFile(file);
    }, [selectedImages]);

    return (
        <Box>
            <Typography fontWeight={700}>Bank Transfer/Deposit</Typography>
            <Typography>Donate via Bank Deposit, Bank Transfer, or Wire Transfer to:</Typography>
            <Card sx={{ p: 2, mt: 2, mb: 2 }}>
                <Typography variant="body2">
                    Account Name: CharityPhilippines.org
                </Typography>
                <Typography variant="body2">Account Number: 2447244520193</Typography>
                <Typography variant="body2">
                    Bank Name: Metropolitan Bank and Trust Co. (Metrobank)
                </Typography>
                <Typography variant="body2">
                    Bank Address: Upper Ground Floor Farmers Plaza, Araneta Center, Quezon
                    City, Metro Manila, Philippines
                </Typography>
            </Card>
            <Typography>Let us know about your donation by filling up donation details below.</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={2}>
                    <Grid item md={6} xs={12}>
                        <RHFTextField autoFocus fullWidth label="First Name" name='firstName' />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RHFTextField fullWidth label="Last Name" name='lastName' />
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12}>
                            <RHFTextField fullWidth label="Email" name='email' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography px={2} variant="caption">We will send your donation receipt to the email address provided.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RHFTextField fullWidth label="Address" name='address' />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RHFTextField type="number" fullWidth label="Contact Number" name='phone' />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Autocomplete value={countries.find(c => c.code === values.country)}
                            fullWidth
                            onChange={(ev, e) => setValue('country', e.code)} renderInput={(e) => <TextField label="Country" {...e} />}
                            options={countries}
                            getOptionLabel={(e) => e?.label ?? ''}
                            isOptionEqualToValue={(a, b) => a.code === b.code}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RHFDatePicker label="Date of Bank Transfer/Deposit" fullWidth name="dobTransfer" />
                    </Grid>
                    <Grid item xs={12}>
                        <UploadPhoto
                            error={errors.file}
                            name="file"
                            label="Upload photo of deposit slip/screenshot of online banking transaction (optional)"
                            selectedImages={selectedImages ?? []}
                            setSelectedImages={setSelectedImages}
                        />
                    </Grid>
                </Grid>
                <Typography mt={5} fontSize={16}>If you have questions and clarificaions in partnering with ChaPhi, please send us a message <span>
                    <Link href="/contact-us" target="_blank">
                        here
                    </Link>
                </span> or email us at contact@charityphilippines.org</Typography>
                <Grid mt={2} container alignItems="center">
                    <Grid item xs={2} md={.5}>
                        <Checkbox />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography fontSize={16} width='100%' sx={{wordWrap: 'break-word'}}>I am interested to receive CharityPhilippines.org's email updates</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2} sx={{flexDirection: {xs: 'column', md: 'row'}, alignItems: {xs: 'start', md: 'center'}}}>
                    <Typography variant="body1" sx={{ mr: 2 }}>
                        Where did you hear about CharityPhilippines.org?
                    </Typography>
                    <Grid item xs={3}>
                        <Select value={values.charitySource ?? "_"} fullWidth onChange={(e) => setValue('charitySource', e.target.value)} sx={{minWidth: 200}}>
                            <MenuItem value="_">Please Choose</MenuItem>
                            <MenuItem value="Family or Friend">Family or Friend</MenuItem>
                            <MenuItem value="Proactively Looking">Proactively Looking</MenuItem>
                            <MenuItem value="Workplace">Workplace</MenuItem>
                            <MenuItem value="Social Media">Social Media</MenuItem>
                            <MenuItem value="Social Media Advertising">
                                Social Media Advertising
                            </MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                {values.charitySource === "Others" && (
                    <Box width={220}>
                        <RHFTextField name="charityOtherSource" label="Specify Other" />
                    </Box>
                )}
                <Grid container justifyContent='center' py={5}>
                    <Grid item xs={4}>
                        <LoadingButton loading={isLoading} disabled={disabled} fullWidth size="large" variant="contained" type='submit'>
                            SUBMIT
                        </LoadingButton>
                    </Grid>
                </Grid>
            </FormProvider>
            <NoAmountModal open={noAmountOpen} onClose={() => setNoAmountOpen(false)} />
        </Box>
    )
}

const createPayload = async ({ discretionary, formData, projects, images, causes }) => {
    const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        contactNumber: formData.phone,
        address: formData.address ? formData.address : ' ',
        country: formData.country,
        donationAmount: formData.donationAmount,
        dobTransfer: fDateDb(formData.dobTransfer),
        images,
        imagesBase64: [],
        emailUpdate: formData.emailUpdate ?? false,
        charitySource: formData.charitySource === 'Others' ? formData.charityOtherSource : formData.charitySource,
        projects: projects.map(e => ({ ...e, charityName: e.charityDetails.name, id: e._id })),
        discretion: {
            isDiscretion: discretionary,
            causes
        }
    };
    payload.imagesBase64 = await convertImagesToBase64STring(payload.images);
    return payload;
}