import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// @miu
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Link,
  Typography,
  Box,
} from '@mui/material';
import Iconify from '../../components/Iconify';

const BoldText = styled('div')(() => ({
  fontWeight: 700,
  margin: '0 1px',
  display: 'inline-block',
  textIndent: 0,
}));

const CustomLink = styled('button')(() => ({
  background: 'none',
  border: 'none',
  padding: 0,
  fontSize: '15px',
  color: '#0275B5',
  cursor: 'pointer',
  margin: '0 4px',
  '&:hover': { textDecoration: 'underline' },
}));

PrivacyPolicy.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function PrivacyPolicy({ open, handleClose }) {
  const THIRD = [
    {
      text: '•	Establish the identity of the donor in order to provide services and accord the benefits of a donor, which includes managing, administering, approving or facilitating any trans-action or action in relation to donations;',
    },
    {
      text: '•	Establish the identity of the beneficiary in order to provide services and accord the benefits of a beneficiary which includes managing, administering, approving or facilitat-ing any transaction or action in relation to a corporate social responsibility project per-taining to the beneficiary;',
    },
  ];

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Box
      sx={{
        mb: { xs: 1, md: 0 },
      }}
    >
      <Dialog
        fullWidth
        maxWidth={'80vw'}
        height={'80vh'}
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle component='div' id="scroll-dialog-title">
          <Typography variant="h4" textAlign={'center'}>
            PRIVACY NOTICE WITH CONSENT SIGNATURE PAGE
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon={'ci:close-big'} />
          </IconButton>
          <Divider sx={{ mt: 2 }} />
        </DialogTitle>
        <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
          <DialogContentText
            component={'div'}
            width={'90%'}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography mt={1} variant="h5" textAlign={'center'}>
              DATA PRIVACY POLICY
            </Typography>

            <Typography mt={3} variant="h6">
              I. INTRODUCTION
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              We at <BoldText>101 FOUNDATION, INC.</BoldText> operating under the name of
              <BoldText>CharityPhilippines.org</BoldText> (<BoldText>ChaPhi</BoldText>, “the
              <BoldText>Foundation</BoldText>”, “<BoldText>101 Foundation</BoldText>”, “<BoldText>we</BoldText>”, “
              <BoldText>us</BoldText>” or “<BoldText>our</BoldText>”) value privacy and are committed to protect and
              respect Personal Information that may be given/submitted to us as part of our ordinary course of business.
              Our Privacy Policy describes how we collect, use, protect and manage information we gather from the public
              through our websites, portals, office, and when directly or indirectly transacting with us, our officers,
              volunteers, and employees or during browsing activities through our website or mobile application/s, as a
              donor, a beneficiary, a part-ner organization, a service provider or in any relevant capacity. We ensure
              that our Privacy Poli-cy is consistent with <BoldText>Republic Act No. 10173</BoldText> or the Data
              <BoldText>Privacy Act of 2012 (“DPA”)</BoldText>, its Im-plementing Rules and Regulations (“
              <BoldText>IRR</BoldText>”), other issuances of the National Privacy Commission (“<BoldText>NPC</BoldText>
              ”), as well as other relevant laws of the Philippines.
            </Typography>

            <Typography mt={5} variant="h6">
              II. PERSONAL INFORMATION THAT ARE COLLECTED
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              The Personal Information that <BoldText>ChaPhi</BoldText> may collect from you in the ordinary course of
              the Company's business which may include, to the extent necessary to respond to your requests, queries and
              concerns, basic information such as your name, residence, and contact details, and sensitive personal
              information such as your age, tax identification number, government issued identification cards, financial
              information, tax returns, educational background, among others (collectively, “Personal Information”).
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              Furthermore, when you visit our website other information that may also constitute Per-sonal Data, such as
              your IP address, domain name, number of times and dates you visited the website and the amount of time you
              spent browsing through the website may be collected via cookies and other tracking technologies.
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              Your Personal Information that we collect are those that you voluntarily submitted to us. By submitting
              your Personal Information pursuant to any of your dealings with <BoldText>ChaPhi</BoldText>, you consent
              to the collection, use, storage and all other forms of processing of your Personal Information in
              accordance with this Privacy Policy.
            </Typography>

            <Typography mt={5} variant="h6">
              III. PURPOSE OF COLLECTED PERSONAL INFORMATION
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              In addition to paragraph II, ChaPhi also gathers your personal information to:
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              • Establish the identity of the donor in order to provide services and accord the benefits of a donor,
              which includes managing, administering, approving or facilitating any trans-action or action in relation
              to donations;
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              • Establish the identity of the beneficiary in order to provide services and accord the benefits of a
              beneficiary which includes managing, administering, approving or facilitat-ing any transaction or action
              in relation to a corporate social responsibility project per-taining to the beneficiary;
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              • Establish the identity of the supplier, service contractor or partner in order to provide the services
              and accord the responsibilities and/or obligations of the supplier, service provider, or partner which
              includes managing, administering, approving or facilitating any transaction or action in relation to
              payment of the service you provided;
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              • Comply with the requirements of any and all regulatory agencies and instrumentalities of the government
              including, but not limited, to the Securities and Exchange Commis-sion (SEC) and Bureau of Internal
              Revenue (BIR); and
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              • Comply with relevant laws and responding to legal processes or lawful orders of any judicial or
              quasi-judicial bodies.
            </Typography>

            <Typography mt={5} variant="h6">
              IV. STORAGE AND RETENTION OF PERSONAL INFORMATION
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              <BoldText>ChaPhi</BoldText> takes the needed precautions and employs the necessary technical and physical
              measures to protect the safety and confidentiality of your Personal Information.
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              Your Personal Information are stored in a secure environment and are only accessible to designated and
              authorized employees, and our supplier and/or service contractors who may be required to process your
              information.
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              Our website/servers shall be protected by firewall and other security tools. Such web-site/servers shall
              be scanned regularly for any security loopholes, in order to avoid any vulner-abilities and to ensure that
              your Personal Information are always protected.
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              We may retain your Personal Data only for as long as necessary for legitimate business purposes, to comply
              with laws, regulations, or lawful court order, or to establish or defend a le-gal action.
            </Typography>

            <Typography mt={5} variant="h6">
              V. DATA PROTECTION OFFICER
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              The Data Protection Officer (“<BoldText>DPO</BoldText>”) is responsible for supervising, checking and
              enforcing compliance of this Policy. The relevant contact details of the DPO are as follows:
            </Typography>
            <Stack direction={'row'} width="inherit" ml={'40px'} mt={2} spacing={2}>
              <BoldText>Title/Designation</BoldText>
              <Typography variant="subtitle1">:</Typography>
              <Typography variant="subtitle1">Data Protection Officer</Typography>
            </Stack>
            <Stack direction={'row'} width="inherit" ml={'40px'} mt={1} spacing={2}>
              <Typography variant="subtitle1" sx={{ width: '150px' }}>
                <BoldText>Postal Address</BoldText>
              </Typography>
              <Typography variant="subtitle1">:</Typography>
              <Typography variant="subtitle1">
                Unit 204, 2/F Vivaldi Condominium, 628 EDSA cor Aurora Blvd., Socorro, Cubao, Que-zon City, Metro
                Manila, Philippines
              </Typography>
            </Stack>
            <Stack direction={'row'} width="inherit" ml={'40px'} mt={1} spacing={2}>
              <BoldText>Telephone Number</BoldText>
              <Typography variant="subtitle1">:</Typography>
              <Typography variant="subtitle1">+63 2 8280 9773</Typography>
            </Stack>
            <Stack direction={'row'} width="inherit" ml={'40px'} mt={1} spacing={2}>
              <BoldText>Email Address</BoldText>
              <Typography variant="subtitle1">:</Typography>
              <Typography variant="subtitle1">contact@charityphilippines.org</Typography>
            </Stack>
            <Typography mt={3} variant="subtitle1" sx={{ textIndent: '40px' }}>
              If you have any inquiries or concerns related to this Privacy Policy or your Personal Infor-mation, please
              contact our DPO through the contact details indicated above.
            </Typography>

            <Typography mt={5} variant="h6">
              VI. RIGHT TO ACCESS, CHANGE OR DELETE PERSONAL INFORMATION
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              You have the right to access your Personal Information stored with us by requesting a copy thereof from
              our DPO. For any request for corrections, changes or deletion of your Per-sonal Information, please inform
              our DPO as soon as possible so that the necessary changes may be reflected in our data base.
            </Typography>

            <Typography mt={5} variant="h6">
              VII. CHANGES AND UPDATES TO THE PRIVACY POLICY
            </Typography>
            <Typography mt={2} variant="subtitle1" sx={{ textIndent: '40px' }}>
              ChaPhi reserves the right to revise or change this Privacy Policy at any time. We shall in-form you of
              such changes by posting the revised or amended Privacy Policy in our website with a date indicated
              therein.
            </Typography>
            <Divider sx={{ mt: 10 }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
