import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { CloseIcon } from '../../../theme/overrides/CustomIcons';
import { dispatch, useSelector } from '../../../redux/store';
import {
  removeAllProjectsEvent,
  removeProjectEvent,
  setBasketModal,
  setProjectAmount,
} from '../../../redux/slices/donation';
import DonationForm from './donation_forms/DonationForm';
import DonationSuccessModal from './DonationSuccessModal';

export default function BasketModal() {
  const { projects, isModalOpen: open } = useSelector((store) => store.donation);

  const dispatch = useDispatch();

  const [successOpen, setSuccessOpen] = useState(false);
  const [currency, setCurrency] = useState('PHP');
  const [projectDonations, setDonations] = useState([]);
  const [showAmountFields, setShowAmountFields] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const json = JSON.stringify(projects);
    setDonations(JSON.parse(json));
  }, [projects]);

  const onClose = () => {
    dispatch(setBasketModal(false));
  };

  const onAddProject = () => {
    if (location.pathname !== '/explore-project') {
      window.location.href = '/explore-project';
    } else {
      dispatch(setBasketModal(false));
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogTitle style={{ maxWidth: '75%' }}>
          <Typography variant="h5" mb={2}>
            Giving Basket ({projects.length} project selected)
          </Typography>
          {/* <Button
            onClick={onAddProject}
            sx={{
              position: 'absolute',
              right: 80,
              top: 22,
            }}
            variant="contained"
          >
            ADD PROJECT
          </Button> */}
          <IconButton
            aria-label="close"
            onClick={() => {
              onClose();
            }}
            sx={{
              position: 'absolute',
              right: 20,
              top: 22,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflowX: 'hidden' }}>
          {projects.length !== 0 ? (
            <Grid container direction="column" pb={5} pt={2} spacing={2}>
              {projectDonations.map((p, i) => (
                <Grid key={p._id} item>
                  <Card sx={{ padding: 3, border: '1px solid #eee' }}>
                    <Grid
                      container
                      spacing={5}
                      sx={{ flexDirection: { xs: 'column', md: 'row' }, pt: { xs: 3, md: 0 } }}
                      alignItems="stretch"
                    >
                      <Grid item xs={3} width="100%" maxWidth="unset">
                        <img
                          width="100%"
                          height={200}
                          src={p.mainImage}
                          alt="project-img"
                          style={{ borderRadius: 12, objectFit: 'cover' }}
                        />
                      </Grid>
                      <Grid item xs={8} container spacing={2} direction="column" justifyContent="center" maxWidth='unset'>
                        <Grid item>
                          <Typography variant="h5">{p.name}</Typography>
                          <Typography variant="body2">{p.charityDetails.name}</Typography>
                        </Grid>
                        {showAmountFields && (
                          <Grid item container spacing={1} alignItems="center">
                            <Grid item xs={4}>
                              <Select
                                value={currency}
                                inputProps={{ sx: { bgcolor: 'white' } }}
                                onChange={(e) => setCurrency(e.target.value)}
                                style={{ marginRight: 0 }}
                              >
                                <MenuItem value="PHP">PHP</MenuItem>
                                <MenuItem value="USD">USD</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                type="number"
                                value={projectDonations[p._id]?.amount}
                                fullWidth
                                inputProps={{
                                  sx: { textAlign: 'right' },
                                }}
                                placeholder="0.00"
                                onChange={(e) => {
                                  const _donations = [...projectDonations];
                                  _donations[i].amount = parseFloat(e.target.value || '0');
                                  setDonations(_donations);
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        dispatch(removeProjectEvent(p._id));
                      }}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
              <Grid container justifyContent="center" mt={5} mb={showAmountFields ? 5 : 10}>
                <Button onClick={onAddProject} variant="contained">
                  ADD PROJECT
                </Button>
              </Grid>
              {showAmountFields && (
                <Grid item container justifyContent="center">
                  <Grid item>
                    <Box
                      height="100%"
                      width="100%"
                      minHeight="60px"
                      bgcolor="white"
                      p={2}
                      border="#ccc 1px solid"
                      sx={{ minWidth: { xs: 'unset', md: 600 } }}
                    >
                      <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
                        <Grid item>
                          <Typography variant="h5">Total Donation Amount:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">
                            {currency}{' '}
                            {Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              currency: 'PHP',
                            }).format(
                              projectDonations.reduce((prev, val, i) => prev + parseFloat(val.amount || '0'), 0)
                            ) || 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item>
              <Typography variant="h8">Please select at least one (1) project.</Typography>
              <Grid container justifyContent="center" my={3}>
                <Button onClick={onAddProject} variant="contained">
                  ADD PROJECT
                </Button>
              </Grid>
            </Grid>
          )}

          {projects.length !== 0 && (
            <Grid mt="-90px" item>
              <DonationForm
                customCurrency={currency}
                projects={projectDonations}
                onSuccess={() => {
                  onClose();
                  setSuccessOpen(true);
                  dispatch(removeAllProjectsEvent());
                }}
                onPaymentMethodChange={(e) => {
                  if (e === 'goods' || e === 'volunteer') {
                    setDonations(
                      projectDonations.map((e) => {
                        delete e.amount;
                        return e;
                      })
                    );
                    setShowAmountFields(false);
                  } else {
                    setShowAmountFields(true);
                  }
                }}
              />
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <DonationSuccessModal open={successOpen} onClose={() => setSuccessOpen(false)} />
    </>
  );
}
