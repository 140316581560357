import { m } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { MotionViewport, varFade } from '../components/animate';
import Image from '../components/Image';
// ----------------------------------------------------------------------

export default function PhilippineMap({whatWeDoImage}) {
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  let width = '551';
  let height = '573';
  
  if (matches) {
     width = '351';
     height = '373';
  }

  return (
    
    <Box component={MotionViewport}>
      {/* <Image src={whatWeDoImage}/> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
        viewBox="0 0 751 873"
      >
        <g id="map_svg" transform="translate(69.88 98.097)">
          <m.g id="map" variants={varFade().inUp}>
            <image
              id="philippine_map"
              width="751"
              height="873"
              transform="translate(-69.88 -98.097)"
              opacity="0.796"
              xlinkHref={whatWeDoImage}
            />
          </m.g>
          <m.g id="map_lines" variants={varFade().inDown}>
            <path
              id="Path_164"
              data-name="Path 164"
              d="M-9129.629-6770.227s196.889-285.03,532.084-58.386"
              transform="translate(9140.36 7224.967)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
              strokeDasharray="8"
            />
            <path
              id="Path_165"
              data-name="Path 165"
              d="M-8903-6824.5s64.519-160.053,190.714-93.813"
              transform="translate(9243.665 7216.662)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
              strokeDasharray="8"
            />
            <path
              id="Path_166"
              data-name="Path 166"
              d="M-9024.263-6543.5s57.121-383.417,454.319-212.722"
              transform="translate(9188.39 7277.91)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
              strokeDasharray="8"
            />
            <path
              id="Path_167"
              data-name="Path 167"
              d="M-8819.812-6748.491s60.03-92.391,143.926-39.555"
              transform="translate(9281.585 7275.924)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
              strokeDasharray="8"
            />
            <path
              id="Path_168"
              data-name="Path 168"
              d="M-8878.148-6570.637s-288.733-226.87-15.672-434.878"
              transform="translate(9194.322 7184.233)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
              strokeDasharray="8"
            />
            <path
              id="Path_169"
              data-name="Path 169"
              d="M-8953.978-7123.516s515.524-82.469,315.607,439.25"
              transform="translate(9220.428 7128.273)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
              strokeDasharray="8"
            />
            <path
              id="Path_171"
              data-name="Path 171"
              d="M1625.329,2809.891s-211.817-73.242-278.964,96.414"
              transform="translate(-1198.794 -2596.986)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="2"
              strokeDasharray="5"
            />
            <circle
              id="Ellipse_53"
              data-name="Ellipse 53"
              cx="8"
              cy="8"
              r="8"
              transform="translate(0.12 451.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_54"
              data-name="Ellipse 54"
              cx="8"
              cy="8"
              r="8"
              transform="translate(310.12 605.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_55"
              data-name="Ellipse 55"
              cx="5"
              cy="5"
              r="5"
              transform="translate(159.12 732.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_56"
              data-name="Ellipse 56"
              cx="5"
              cy="5"
              r="5"
              transform="translate(457.12 522.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_57"
              data-name="Ellipse 57"
              cx="5"
              cy="5"
              r="5"
              transform="translate(603.12 483.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_58"
              data-name="Ellipse 58"
              cx="9"
              cy="9"
              r="9"
              transform="translate(608.12 513.903)"
              fill="#ff9e3d"
            />
            <ellipse
              id="Ellipse_59"
              data-name="Ellipse 59"
              cx="9"
              cy="8.5"
              rx="9"
              ry="8.5"
              transform="translate(336.12 378.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_60"
              data-name="Ellipse 60"
              cx="5"
              cy="5"
              r="5"
              transform="translate(524.12 291.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_66"
              data-name="Ellipse 66"
              cx="5"
              cy="5"
              r="5"
              transform="translate(536.12 390.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_61"
              data-name="Ellipse 61"
              cx="6"
              cy="6"
              r="6"
              transform="translate(576.12 439.903)"
              fill="#ff9e3d"
            />
            <ellipse
              id="Ellipse_62"
              data-name="Ellipse 62"
              cx="5.5"
              cy="6"
              rx="5.5"
              ry="6"
              transform="translate(421.12 206.903)"
              fill="#ff9e3d"
            />
            <ellipse
              id="Ellipse_63"
              data-name="Ellipse 63"
              cx="6"
              cy="5.5"
              rx="6"
              ry="5.5"
              transform="translate(298.12 170.903)"
              fill="#ff9e3d"
            />
            <circle
              id="Ellipse_64"
              data-name="Ellipse 64"
              cx="9.5"
              cy="9.5"
              r="9.5"
              transform="translate(137.12 301.903)"
              fill="#ff9e3d"
            />
            <ellipse
              id="Ellipse_65"
              data-name="Ellipse 65"
              cx="6.5"
              cy="7"
              rx="6.5"
              ry="7"
              transform="translate(256.12 -0.097)"
              fill="#ff9e3d"
            />
          </m.g>
        </g>
      </svg>
    </Box>
  );
}
